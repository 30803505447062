import React from 'react'
import { connect } from 'react-redux'

import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { changeModalScreen, closeModal } from 'mednet-cns/src/reducers/modal'
import {
  fetchDigestSubjectsPreview,
  getDigest,
} from 'mednet-cns/src/reducers/digest'

import { EMAIL_PREVIEW_MODAL } from 'mednet-util/src/constants/modal'

import { Header4 } from 'pharmacy/src/typography'

import classNames from 'classnames'

import css from './subjectsPreviewScreen.scss'

class SubjectsPreviewScreen extends React.Component {
  componentDidMount() {
    const { digestId, specialtyId, fetchDigestSubjectsPreview } = this.props

    fetchDigestSubjectsPreview(digestId, specialtyId)
  }

  renderPreview() {
    const { subjectsPreview } = this.props

    if (!subjectsPreview || !subjectsPreview.length) {
      return <StarLoader />
    }

    return (
      <ul className={css.list}>
        <li className={classNames(css.header, css.row)}>
          <div className={css.subspecialty}>
            <Header4>SUBSPECIALTY</Header4>
          </div>
          <div className={css.subject}>
            <Header4>SUBJECT</Header4>
          </div>
        </li>
        {subjectsPreview.map((item, i) => (
          <li key={i} className={css.row}>
            <div className={css.subspecialty}>{item.subspecialty}</div>
            <div className={css.subject}>{item.subject}</div>
          </li>
        ))}
      </ul>
    )
  }

  render() {
    const { closeModal, changeScreen } = this.props

    return (
      <div className={css.contents}>
        <div className={css.preview}>{this.renderPreview()}</div>
        <div className={css.buttonContainer}>
          <Button
            onClick={changeScreen}
            type="secondary"
            className={css.button}
            size="small"
          >
            Show preview
          </Button>
          <Button
            onClick={closeModal}
            type="secondary"
            className={css.button}
            size="small"
          >
            Close
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { digestId } = ownProps
  const subjectsPreview = getDigest(state.digest, digestId).subjectsPreview

  return {
    subjectsPreview,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal(EMAIL_PREVIEW_MODAL.modalId)),
  fetchDigestSubjectsPreview: (digestId, specialtyId) =>
    dispatch(fetchDigestSubjectsPreview(digestId, specialtyId)),
  changeScreen: () =>
    dispatch(
      changeModalScreen(
        EMAIL_PREVIEW_MODAL.modalId,
        EMAIL_PREVIEW_MODAL.screens.digest
      )
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectsPreviewScreen)
