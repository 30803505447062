import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'

import { QUESTION_SHORT_TITLE_MODAL } from 'mednet-util/src/constants/modal'

import questionShortTitleScreen from './questionShortTitleScreen'

const screens = {
  [QUESTION_SHORT_TITLE_MODAL.screens.form]: questionShortTitleScreen,
}

class QuestionShortTitleModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop
        defaultScreen={QUESTION_SHORT_TITLE_MODAL.screens.form}
        modalId={QUESTION_SHORT_TITLE_MODAL.modalId}
        screens={screens}
      ></Modal>
    )
  }
}

export default QuestionShortTitleModal
