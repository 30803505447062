import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { SimpleSelect } from 'pharmacy/src/input/select'
import { SimpleDropdownIndicator } from 'pharmacy/src/input/select/components'
import { Header3 } from 'pharmacy/src/typography'

import { changeModalScreen, closeModal } from 'mednet-cns/src/reducers/modal'
import { fetchDigestPreview, getDigest } from 'mednet-cns/src/reducers/digest'

import { EMAIL_PREVIEW_MODAL } from 'mednet-util/src/constants/modal'

import { fetchSpecialtySubspecialties } from 'mednet-cns/src/reducers/subspecialty'

import css from './emailPreviewScreen.scss'

class DigestPreviewScreen extends React.Component {
  componentDidMount() {
    const {
      specialtyId,
      digestId,
      fetchDigestPreview,
      fetchSpecialtySubspecialties,
      subspecialties,
    } = this.props

    if (!subspecialties || !subspecialties.length) {
      fetchSpecialtySubspecialties(specialtyId)
    }

    fetchDigestPreview(digestId)
  }

  renderPreview() {
    const { preview } = this.props

    if (!preview) {
      return <StarLoader />
    }

    return (
      <div
        className={css.preview}
        dangerouslySetInnerHTML={{ __html: preview }}
      ></div>
    )
  }

  handleChangeSubspecialty = (option) => {
    const { digestId, fetchDigestPreview } = this.props

    fetchDigestPreview(digestId, option.value)
  }

  renderSubspecialtiesDropdown() {
    const { subspecialties } = this.props
    const notSetSubspecialty = {
      label: 'Not set',
      value: 0,
    }
    const options = _.map(
      _.sortBy(subspecialties, 'subspecialty'),
      (subspecialty) => ({
        label: subspecialty.subspecialty,
        value: subspecialty.subspecialtyId,
      })
    )
    options.unshift(notSetSubspecialty)

    if (!subspecialties || !subspecialties.length)
      return <StarLoader></StarLoader>

    return (
      <div className={css.subspecialty}>
        <Header3 className={css.label}>Subspecialty:</Header3>
        <SimpleSelect
          className={css.select}
          options={options}
          defaultValue={notSetSubspecialty}
          onChange={this.handleChangeSubspecialty}
          components={{
            SingleValue: ({ children }) => (
              <Header3 className={css.containerText}>{children}</Header3>
            ),
            DropdownIndicator: SimpleDropdownIndicator,
          }}
        />
      </div>
    )
  }

  render() {
    const { closeModal, changeScreen } = this.props

    return (
      <div className={css.contents}>
        {this.renderSubspecialtiesDropdown()}
        <div className={css.preview}>{this.renderPreview()}</div>
        <div className={css.buttonContainer}>
          <Button
            onClick={changeScreen}
            type="secondary"
            className={css.button}
            size="small"
          >
            Show subjects
          </Button>
          <Button
            onClick={closeModal}
            type="secondary"
            className={css.button}
            size="small"
          >
            Close
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { specialtyId, digestId } = ownProps
  const preview = getDigest(state.digest, digestId)?.preview
  const subspecialties = _.values(
    _.get(state, 'subspecialty.subspecialties', {})
  ).filter((subspecialty) => subspecialty.specialtyId === Number(specialtyId))

  return {
    preview,
    subspecialties,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal(EMAIL_PREVIEW_MODAL.modalId)),
  fetchDigestPreview: (digestId, subspecialtyId) =>
    dispatch(fetchDigestPreview(digestId, subspecialtyId)),
  fetchSpecialtySubspecialties: (specialtyId) =>
    dispatch(fetchSpecialtySubspecialties(specialtyId)),
  changeScreen: () =>
    dispatch(
      changeModalScreen(
        EMAIL_PREVIEW_MODAL.modalId,
        EMAIL_PREVIEW_MODAL.screens.subjects
      )
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(DigestPreviewScreen)
