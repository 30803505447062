import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'

import { EMAIL_PREVIEW_MODAL } from 'mednet-util/src/constants/modal'

import DigestPreviewScreen from './digestPreviewScreen'
import SubjectsPreviewScreen from './subjectsPreviewScreen'

const screens = {
  [EMAIL_PREVIEW_MODAL.screens.digest]: DigestPreviewScreen,
  [EMAIL_PREVIEW_MODAL.screens.subjects]: SubjectsPreviewScreen,
}

class EmailPreviewModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop
        modalId={EMAIL_PREVIEW_MODAL.modalId}
        screens={screens}
        size="large"
      ></Modal>
    )
  }
}

export default EmailPreviewModal
