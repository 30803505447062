import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { createSelector } from 'reselect'

import { Header3 } from 'pharmacy/src/typography'
import { TextEditor } from 'pharmacy/src/input/textEditor'
import { Button } from 'pharmacy/src/input/button'
import { Icon } from 'pharmacy/src/display/icon'

import {
  SET_DESCRIPTION,
  setDigestDescription,
} from 'mednet-cns/src/reducers/digest'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { getRequest } from 'mednet-cns/src/api/v1'

import css from './digestDescriptionField.scss'

export default function DigestDescriptionField({ digest }) {
  const [digestId, setDigestId] = useState(digest?.digest_id)
  const [hasDescription, setHasDescription] = useState(
    Boolean(digest?.description)
  )
  const [description, setDescription] = useState(digest?.description)

  const editorRef = useRef(null)

  const dispatch = useDispatch()

  const selectIsSaving = useCallback(
    createSelector(
      (state) => getRequest(state, makeRequestName(SET_DESCRIPTION)),
      (descriptionRequest) => {
        return descriptionRequest.isLoading
      }
    ),
    []
  )

  const isSaving = useSelector(selectIsSaving)

  const updateDigestDescription = useCallback(
    _.debounce((value) => {
      dispatch(setDigestDescription(digest?.digest_id, value))
    }, 1000),
    [digest?.digest_id]
  )

  useEffect(() => {
    if (digest?.digest_id !== digestId) {
      setDigestId(digest?.digest_id)
      setHasDescription(Boolean(digest?.description))
      setDescription(digest?.description)
    } else {
      if (hasDescription && !_.isEmpty(description)) {
        updateDigestDescription(description)
      } else {
        updateDigestDescription(null)

        if (!_.isEmpty(description)) {
          setDescription(null)
        }
      }
    }
  }, [hasDescription, description, digestId, digest?.digest_id])

  const syncedChanges = useMemo(() => {
    if (
      (!hasDescription || _.isEmpty(description)) &&
      _.isEmpty(digest?.description)
    ) {
      return true
    }
    if (description !== digest?.description) {
      return false
    }
    return true
  }, [description, digest?.description])

  useEffect(() => {
    if (!editorRef.current) {
      return
    }

    if (syncedChanges) {
      editorRef.current.settings.autosave_ask_before_unload = false
    } else {
      editorRef.current.settings.autosave_ask_before_unload = true
    }
  }, [syncedChanges])

  if (!hasDescription) {
    return (
      <div className={css.container}>
        <Header3 className={css.title}>
          <input
            type="checkbox"
            checked={hasDescription}
            onChange={() => setHasDescription((prev) => !prev)}
          />
          <span onClick={() => setHasDescription((prev) => !prev)}>
            Add a description
          </span>
        </Header3>
      </div>
    )
  }

  return (
    <div className={css.container}>
      <Header3 className={css.title}>Discription</Header3>

      <div>
        <TextEditor
          toolbar="styleselect | fontsizeselect | hr | undo redo | indent outdent | alignleft aligncenter alignright alignjustify | bold italic underline | removeformat | numlist bullist | table | link unlink | image"
          onEditorChange={(content) => {
            setDescription(content)
          }}
          value={description}
          init={{
            // Since we auto save to backend, do not show the alert when leaving the page, this is changed later if changes are not synced yet
            autosave_ask_before_unload: false,
          }}
          onInit={(_evt, editor) => (editorRef.current = editor)}
        />
      </div>
      <div className={css.removeDescriptionButton}>
        {!syncedChanges && isSaving && (
          <div className={css.saving}>
            <Icon icon={['far', 'spinner']} size="small" /> Saving latest
            changes...
          </div>
        )}
        {!syncedChanges && !isSaving && (
          <div className={css.notSaved}>
            <Icon icon={['fas', 'exclamation']} size="small" /> Latest changes
            not saved yet
          </div>
        )}
        {syncedChanges && (
          <div className={css.saved}>
            <Icon icon={['far', 'check-double']} size="small" /> Saved changes
          </div>
        )}
        <div>
          <Button
            className={css.removeUpdateButton}
            type="destructive"
            icon={['far', 'trash-alt']}
            size="small"
            onClick={() => {
              setHasDescription(false)
              dispatch(setDigestDescription(digest?.digest_id, null))
            }}
          >
            Remove description
          </Button>
        </div>
      </div>
    </div>
  )
}
