import React from 'react'
import { connect } from 'react-redux'

import { putShortQuestion } from 'mednet-cns/src/reducers/question'

import { Header1, Header3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import TextInput from 'pharmacy/src/input/textInput/textInput'

import { closeModal } from 'mednet-cns/src/reducers/modal'
import { QUESTION_SHORT_TITLE_MODAL } from 'mednet-util/src/constants/modal'

import css from './questionShortTitleScreen.scss'

class QuestionShortTitleScreen extends React.Component {
  state = {
    short: this.props.question.short,
  }

  handleTextChange = (short) => this.setState({ short })

  onSubmit = () => {
    const { putShortQuestion, closeShortQuestionTitleModal } = this.props
    const { short } = this.state

    putShortQuestion(short, () => closeShortQuestionTitleModal())
  }

  onCancel = () => {
    const { closeShortQuestionTitleModal } = this.props
    closeShortQuestionTitleModal()
  }

  render() {
    const { question } = this.props
    const { short } = this.state

    return (
      <div>
        <Header1>Specify short version of following question</Header1>
        <div className={css.question}>
          <Header3>{question.question}</Header3>
        </div>
        <div className={css.inputContainer}>
          <TextInput
            name="short"
            showClear={false}
            value={short}
            onChange={this.handleTextChange}
          />
        </div>
        <div className={css.buttonContainer}>
          <Button onClick={this.onSubmit} className={css.button}>
            Submit
          </Button>
          <Button
            onClick={this.onCancel}
            type="secondary"
            className={css.button}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  putShortQuestion: (short, callback) =>
    dispatch(putShortQuestion(ownProps.question.questionId, short, callback)),
  closeShortQuestionTitleModal: () =>
    dispatch(closeModal(QUESTION_SHORT_TITLE_MODAL.modalId)),
})

export default connect(null, mapDispatchToProps)(QuestionShortTitleScreen)
