import React from 'react'

import { SimpleSelect } from 'pharmacy/src/input/select'
import { SimpleDropdownIndicator } from 'pharmacy/src/input/select/components'
import TextInput from 'pharmacy/src/input/textInput/textInput'
import { Header1 } from 'pharmacy/src/typography'

import * as css from './emailAutoSubject.scss'

class EmailAutoSubject extends React.Component {
  constructor(props) {
    super(props)

    if (props.subject === null || props.subject === undefined) {
      this.state = { type: 'auto' }
    } else {
      this.state = { type: 'custom' }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subject !== this.props.subject) {
      if (this.props.subject === null || this.props.subject === undefined) {
        if (prevState.type !== 'auto') {
          this.handleSubjectTypeChange({ value: 'auto' })
        }
      } else {
        if (prevState.type !== 'custom') {
          this.handleSubjectTypeChange({ value: 'custom' })
        }

        this.handleSubjectChange(this.props.subject)
      }
    }
  }

  handleSubjectTypeChange = (option) => {
    const { onSubjectChange } = this.props

    this.setState({
      type: option.value,
    })

    if (option.value === 'auto' && onSubjectChange) onSubjectChange(null)
  }

  handleSubjectChange = (value) => {
    const { onSubjectChange } = this.props

    this.setState({
      subject: value,
    })

    if (onSubjectChange) onSubjectChange(value)
  }

  renderInputbox() {
    const { subject } = this.props
    return (
      <TextInput
        value={subject ?? ''}
        showClear={false}
        placeholder="Input custom subject..."
        className={css.input}
        onChange={this.handleSubjectChange}
      ></TextInput>
    )
  }

  render() {
    const { type } = this.state
    const options = [
      {
        label: 'Auto generated subject',
        value: 'auto',
      },
      {
        label: 'Customized subject',
        value: 'custom',
      },
    ]

    const selectable = options.filter((option) => option.value !== type)
    const selectValue = options.find((option) => option.value === type)

    return (
      <div className={css.wrapper}>
        <SimpleSelect
          className={css.select}
          options={selectable}
          value={selectValue}
          onChange={this.handleSubjectTypeChange}
          components={{
            SingleValue: ({ data }) => {
              return (
                <div className={css.container}>
                  {data.value === 'custom' ? null : (
                    <Header1 className={css.header}>
                      Auto generated subject
                    </Header1>
                  )}
                </div>
              )
            },
            DropdownIndicator: SimpleDropdownIndicator,
          }}
        />
        {type !== 'custom' ? null : this.renderInputbox()}
      </div>
    )
  }
}

export default EmailAutoSubject
