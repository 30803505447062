import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'

import {
  fetchUpdateInNotificationUsage,
  FETCH_UPDATE_IN_NOTIFICATION_USAGE,
  searchQuestionUpdate,
  SEARCH_FOR_UPDATES,
} from 'mednet-cns/src/reducers/questionUpdate'
import { getRequest } from 'mednet-cns/src/api/v1'
import { makeRequestName } from 'mednet-cns/src/reducers/request'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { TabButton } from 'pharmacy/src/navigation/tabs'
import { DateRangePicker } from 'pharmacy/src/input/datePicker'
import TextInput from 'pharmacy/src/input/textInput/textInput'
import { TagSearch } from 'pharmacy/src/display/tagSearch'

import { Button } from 'pharmacy/src/input/button'

import { ErrorPage } from 'pages/error'
import UpdateCard from 'components/updateCard/updateCard'

import Tabs from './searchQuestionUpdatesListTab'

import css from './searchQuestionUpdates.scss'

const UPDATE_TYPES = {
  ANSWERS: 'answers',
  QUESTIONS: 'questions',
  POLLS: 'polls',
}

const UPDATE_PAGE_SIZE = 25

class SearchQuestionUpdatesPage extends React.Component {
  state = {
    types: _.values(UPDATE_TYPES),
    from: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    text: '',
    topics: [],
    author: 0,
    advanced: false,
    hideUsedUpdates: false,
    page: 1, // page is 1-indexed on backend
  }

  componentDidMount() {
    this.search()
  }

  updateDateRange = ({ from, to }) => {
    const runSearch = from && to
    this.setState({ from, to, page: 1 }, () => runSearch && this.search())
  }

  updateState = (name) => (value) => {
    this.setState({ page: 1, [name]: value }, this.search)
  }

  search = _.debounce(() => {
    const { searchQuestionUpdate } = this.props
    const { types, from, to, text, author, page } = this.state
    const params = {
      types,
      page,
      pageSize: UPDATE_PAGE_SIZE,
    }

    if (from && to) {
      params.from = from
      params.to = to
    }

    if (text) {
      params.text = text
    }

    if (author && author.userId) {
      params.author = author.userId
    }

    searchQuestionUpdate(params, this.fetchUpdateInNotificationUsage)
  }, 1000)

  getTabs = () => {
    let { answers, questions, polls } = this.props
    const { usedUpdates, notifications, isLoadingUpdatesInfo, isLoading } =
      this.props
    const { hideUsedUpdates } = this.state
    const isLoadingAnything = isLoading || isLoadingUpdatesInfo
    const differenceBy = (item) => item.questionUpdateId
    const updateFilter = (update) =>
      !_.get(notifications, `${update.questionUpdateId}.digests`, []).length

    answers = _.differenceBy(answers, usedUpdates, differenceBy)
    questions = _.differenceBy(questions, usedUpdates, differenceBy)
    polls = _.differenceBy(polls, usedUpdates, differenceBy)

    if (hideUsedUpdates) {
      answers = answers.filter(updateFilter)
      questions = questions.filter(updateFilter)
      polls = polls.filter(updateFilter)
    }

    return [
      {
        header: (
          <TabButton
            className={css.tabHeader}
            isLoading={isLoadingAnything}
            title={
              isLoadingAnything ? 'Answers' : `Answers (${answers.length})`
            }
          />
        ),
        component: this.renderTabContent(answers, 'answer'),
      },
      {
        header: (
          <TabButton
            className={css.tabHeader}
            isLoading={isLoadingAnything}
            title={isLoadingAnything ? 'Polls' : `Polls (${polls.length})`}
          />
        ),
        component: this.renderTabContent(polls, 'poll'),
      },
      {
        header: (
          <TabButton
            className={css.tabHeader}
            isLoading={isLoadingAnything}
            title={
              isLoadingAnything
                ? 'Questions'
                : `Questions (${questions.length})`
            }
          />
        ),
        component: this.renderTabContent(questions, 'question'),
      },
    ]
  }

  toggleUsedUpdatesVisiblity = () => {
    this.setState((state) => ({
      hideUsedUpdates: !state.hideUsedUpdates,
    }))
  }

  fetchUpdateInNotificationUsage = (response) => {
    const { fetchUpdateInNotificationUsage } = this.props
    const updateIds = _.concat(
      response.answers,
      response.questions,
      response.polls
    ).map((update) => update.questionUpdateId)

    fetchUpdateInNotificationUsage(updateIds)
  }

  onNextPage = () => {
    this.setState((prev) => ({ page: prev.page + 1 }), this.search)
  }

  onPrevPage = () => {
    this.setState((prev) => ({ page: prev.page - 1 }), this.search)
  }

  renderPaginationButtons = () => (
    <div className={css.pagination}>
      <Button
        icon="chevron-left"
        size="small"
        shape="circle"
        isDisabled={this.state.page <= 1 || this.props.isLoading}
        onClick={this.onPrevPage}
      />
      <span>Page {this.state.page}</span>
      <Button
        icon="chevron-right"
        size="small"
        shape="circle"
        isDisabled={this.props.isLoading}
        onClick={this.onNextPage}
      />
    </div>
  )

  renderFilters() {
    const { from, to, text, author } = this.state
    return (
      <div>
        <div className={css.row}>
          <TextInput
            value={text}
            onChange={this.updateState('text')}
            className={classNames(css.text)}
            placeholder="Search text in question, answer, polls..."
          ></TextInput>
        </div>
        <div className={classNames(css.row)}>
          <TagSearch
            index="user"
            name="author"
            placeholder="Search for author..."
            className={classNames(css.author)}
            onChange={this.updateState('author')}
            value={author}
            isMulti={false}
            hitsPerPage={10}
            openMenuOnClick={false}
            isClearable
          />
          <DateRangePicker
            fromProps={{ defaultValue: from }}
            toProps={{ defaultValue: to, justifyOverlay: 'right' }}
            toDisabledDays={(from) => ({ before: from, after: new Date() })}
            onChange={this.updateDateRange}
            className={css.dateRangePicker}
          />
        </div>
      </div>
    )
  }

  renderTabContent(updates, type) {
    const {
      specialtyId,
      specialtyTitle,
      onAddUpdate,
      updateIsLoading,
      isLoading,
    } = this.props

    if (isLoading) {
      return <StarLoader />
    }

    return (
      <div className={css.tab}>
        {this.renderPaginationButtons()}
        {updates.map((update, i) => (
          <UpdateCard
            key={`${type}.${i}`}
            type={type}
            update={update}
            className={css.card}
            onAddUpdate={onAddUpdate}
            updateIsLoading={updateIsLoading}
            specialtyId={specialtyId}
            specialtyTitle={specialtyTitle}
          ></UpdateCard>
        ))}
      </div>
    )
  }

  renderUpdatesList() {
    const { isError, errorStatus, isUsageLoading } = this.props

    if (isError) {
      return <ErrorPage errorStatus={errorStatus}></ErrorPage>
    }

    return (
      <Tabs
        headerProps={{
          toggleUsedUpdatesVisiblity: this.toggleUsedUpdatesVisiblity,
          isLoading: isUsageLoading,
        }}
        tabs={this.getTabs()}
      />
    )
  }

  render() {
    return (
      <div className={css.main}>
        <div>{this.renderFilters()}</div>
        <hr className={css.divider} />
        <div>{this.renderUpdatesList()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { specialtyId } = ownProps
  const { answers, questions, polls } = state.questionUpdate.search[
    specialtyId
  ] || {
    answers: [],
    questions: [],
    polls: [],
  }
  const notifications = state.questionUpdate.notifications[specialtyId] || {}

  const searchUpdatesRequest = getRequest(
    state,
    makeRequestName(SEARCH_FOR_UPDATES, specialtyId)
  )

  const lastUsedUpdateRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_UPDATE_IN_NOTIFICATION_USAGE)
    ) || {}

  return {
    answers,
    questions,
    polls,
    notifications,
    isLoading: searchUpdatesRequest.isLoading,
    errorStatus: searchUpdatesRequest.errorStatus,
    isError: searchUpdatesRequest.errorStatus,
    isLoadingUpdatesInfo: lastUsedUpdateRequest.isLoading,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchQuestionUpdate: (params, callback) =>
    dispatch(searchQuestionUpdate(ownProps.specialtyId, params, callback)),
  fetchUpdateInNotificationUsage: (updateIds) =>
    dispatch(
      fetchUpdateInNotificationUsage(ownProps.specialtyId, updateIds, {
        digestIds: ownProps.todayDigestIds, // A day can have muliple digests because there can be cohorts
        newsletterIds: [ownProps.newsletterId],
      })
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchQuestionUpdatesPage)
