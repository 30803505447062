import React, { useEffect } from 'react'

import { Header3 } from 'pharmacy/src/typography'

import css from './digestBooleanField.scss'

export default function DigestBooleanField({
  fieldKey,
  fieldLabel,
  submit,
  digest,
}) {
  const [digest_id, setDigestId] = React.useState(digest?.digest_id)
  const [value, setValue] = React.useState(Boolean(digest?.[fieldKey]))

  useEffect(() => {
    if (digest?.digest_id !== digest_id) {
      setDigestId(digest?.digest_id)
      setValue(Boolean(digest?.[fieldKey]))
    } else if (digest?.digest_id && value !== Boolean(digest?.[fieldKey])) {
      submit?.(digest?.digest_id, value)
    }
  }, [value, digest?.digest_id, digest?.[fieldKey], digest_id, submit])

  return (
    <Header3 className={css.container}>
      <input
        type="checkbox"
        checked={value}
        onChange={() => setValue((prev) => !prev)}
      />
      <span onClick={() => setValue((prev) => !prev)}>{fieldLabel}</span>
    </Header3>
  )
}
