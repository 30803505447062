import React from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { Button } from 'pharmacy/src/input/button'

import { Icon } from '../icon'

import { Header2 } from '../../typography'

import css from './sidePanel.scss'

/**
 * For time being only sliding window attached to the bottom-right corner of a page
 */
export default class SidePanel extends React.Component {
  static defaultProps = {
    classesNames: {},
  }

  state = {
    isOpen: false,
  }

  toggle = () => {
    const { onToggle } = this.props
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }))

    if (onToggle) {
      onToggle(!this.state.isOpen)
    }
  }

  render() {
    const { children, classesNames, buttonText, header } = this.props
    const { isOpen } = this.state
    const { containerOpened, containerClosed, container, content } =
      classesNames

    let containerClass

    if (isOpen) {
      containerClass = containerOpened
    } else {
      containerClass = containerClosed
    }

    const contentClasses = classNames(css.content, content)
    const containerClasses = classNames(
      css.container,
      container,
      containerClass,
      { [css.opened]: isOpen }
    )

    return (
      <>
        <CSSTransition
          classNames={{
            enter: css.container_enter,
            enterDone: css.container_enter_done,
            exit: css.container_exit,
            exitActive: css.container_exit_active,
            exitDone: css.container_exit_done,
          }}
          in={isOpen}
          timeout={150}
        >
          <div className={containerClasses}>
            <div className={css.header}>
              {!header ? null : <Header2>{header}</Header2>}
              <Button className={css.button} size="small" onClick={this.toggle}>
                &nbsp;
                <Icon icon={['fal', 'plus']}></Icon>
                &nbsp;
                <span className={css.text}>{buttonText}&nbsp;</span>
              </Button>
            </div>
            <div className={contentClasses}>{children}</div>
            <div />
          </div>
        </CSSTransition>
      </>
    )
  }
}
