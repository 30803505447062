import React from 'react'

import { createTabs } from 'pharmacy/src/navigation/tabs/creators'
import { ButtonCheckbox } from 'pharmacy/src/input/button'

import mainCss from 'pharmacy/src/navigation/tabs/tabs.scss'

import css from './searchQuestionUpdatesListTab.scss'

const HeaderContainer = (props) => {
  const { children, ...rest } = props

  return (
    <div className={mainCss.headerContainer}>
      {props.children}
      <div className={css.checkbox}>
        <ButtonCheckbox
          uncheckedType="secondary"
          inputProps={{
            onChange: rest.toggleUsedUpdatesVisiblity,
          }}
          buttonProps={{
            size: 'small',
            isLoading: rest.isLoading,
          }}
        >
          Hide used updates (local filtering)
        </ButtonCheckbox>
      </div>
    </div>
  )
}

const TabContainer = ({ children }) => (
  <div className={mainCss.tab}>{children}</div>
)

export default createTabs({
  HeaderContainer,
  TabContainer,
  ComponentsContainer: 'div',
})
